.icon-bar2 {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  /* section 7 */
  .Icon2{
    text-decoration: none !important;
  }
  
  .icon-bar2 .Icon2 {
    width: 15rem;
    background: #3b5999;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.2rem;
    padding: 0.5rem;
    transition: all 0.5s linear;
    transform: translateX(-12rem);
    margin-bottom: 0px;
    /* padding-bottom: 0px; */
  }
  
  .icon-bar2 .Icon2:hover {
    transform: translateX(0);
  }
  
  .icon-bar2 .Icon2 .fab {
    padding-left: 1rem;
    font-size: 2rem;
    animation: letszoom 3s linear alternate-reverse infinite;
  }
  .icon3{
    padding-left: 1.5em;
    font-size: 2.4rem;
    animation: letszoom 3s linear alternate-reverse infinite;
    padding: 0px;
    margin-left: 10px;
  }
  .icon-bar2-images {
    padding-left: 1rem !important;
    font-size: 2rem !important;
    animation: letszoom 3s linear alternate-reverse infinite;
  }
  
  @keyframes letszoom {
    from {
      transform: scale(0.6);
    }
    to {
      transform: scale(0.9);
    }
  }
  
  .icon-bar2 .twitter2 {
    background: #00aced;
  }
  
  .icon-bar2 .instagram2 {
    background: #e4405f;
  }
  
  .icon-bar2 .github2 {
    background: #1f1f1f;
  }
  
  .icon-bar2 .linkedin2 {
    background: #0077b5;
  }
  .icon-bar2 .leetcode2 {
    background: #0077b5;
  }
  @media screen and (max-width: 500px) {
  .icon-bar2 .Icon2 {
    display: none;
    visibility: hidden;
    width: 11rem;
    background: #3b5999;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    padding: 0.5rem;
    transition: all 0.5s linear;
    transform: translateX(-8rem);
  }
  .icon-bar2 .Icon2 .fab {
    display: none;
    padding-left: 1rem;
    font-size: 1.5rem;
    animation: letszoom 3s linear alternate-reverse infinite;
  }
  .icon-bar2 .Icon2:hover {
    display: none;
    transform: translateX(-8.7rem);
  }
  

}